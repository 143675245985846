<template>
  <div class="review">
    <div>
      <carousel :autoplay="true" :loop="true" :per-page="1" :mouse-drag="true" :navigationEnabled="true">
        <slide>
          <div class="review__content">
            <img src="@/assets/icons/quote.svg" alt="" class="review__quote" />
            <div class="review__review-section">
              <img src="@/assets/icons/star-yellow.svg" alt="" class="review__star" />
              <img src="@/assets/icons/star-yellow.svg" alt="" class="review__star" />
              <img src="@/assets/icons/star-yellow.svg" alt="" class="review__star" />
              <img src="@/assets/icons/star-yellow.svg" alt="" class="review__star" />
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit explicabo quasi sit voluptate corporis,
              aut asperiores aperiam. Ipsum mollitia delectus, officiis deserunt nihil quos dolores pariatur praesentium
              quo maxime eligendi.
            </p>
            <div class="review__user-img">
              <img src="@/assets/images/user1.jpeg" />
            </div>
            <span class="review__user-text">Ann Smith</span>
            <span class="review__desg">Photographer</span>
          </div>
        </slide>
        <slide>
          <div class="review__content">
            <img src="@/assets/icons/quote.svg" alt="" class="review__quote" />
            <div class="review__review-section">
              <img src="@/assets/icons/star-yellow.svg" alt="" class="review__star" />
              <img src="@/assets/icons/star-yellow.svg" alt="" class="review__star" />
              <img src="@/assets/icons/star-yellow.svg" alt="" class="review__star" />
              <img src="@/assets/icons/star-yellow.svg" alt="" class="review__star" />
              <img src="@/assets/icons/star-yellow.svg" alt="" class="review__star" />
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit explicabo quasi sit voluptate corporis,
              aut asperiores aperiam. Ipsum mollitia delectus, officiis deserunt nihil quos dolores pariatur praesentium
              quo maxime eligendi.
            </p>
            <div class="review__user-img">
              <img src="@/assets/images/user2.jpeg" />
            </div>
            <span class="review__user-text">Charlie Mathew</span>
            <span class="review__desg">Interior Designer</span>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'
export default {
  components: {
    Carousel,
    Slide
  }
}
</script>
<style lang="sass" scoped>
.review
  &__quote
    width: 5rem
  &__review-section
    display: flex
    align-items: center
    justify-content: center
  &__star
    width: 1rem
    margin: 5px
  &__content
    display: flex
    align-items: center
    flex-direction: column
    p
      line-height: 1.3
      color: $text-grey
      padding: 2em 8em
      @media( max-width: $md-breakpoint)
        padding: 1em
  &__user-img
    width: 8rem
    height: 8rem
    border: 2px solid $orange
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
    img
      width: 7rem
      height: 7rem
      border-radius: 100%
  &__desg
    color: $text-grey
    text-transform: uppercase
  &__user-text, &__desg
    margin: 5px
</style>
