<template>
  <div class="about">
    <TopSection from="Home" page="About Us" />
    <Banner :isHome="true"></Banner>
    <div class="photo-design center-wrapper">
      <h1>Great Design for all</h1>
      <p class="padded photo-design__desc">
        Minhas Furniture House Ltd. is a Family owned Company that started with a small unit in 1997. Since its inception Minhas Furniture House’s main objective is to provide customers the finest quality furniture at exceptional values. This is just one of many reasons we are among North America’s fastest growing furniture manufacturers.
      </p>
    </div>
    <div class="center-wrapper">
      <div class="photo-text orange">
        <div class="photo-text__image">
          <img src="@/assets/images/banner4.jpeg" />
        </div>
        <div class="photo-text__content">
          <img src="@/assets/icons/sofat.svg" class="photo-text__small-img" />
          <h3>Design your Desire</h3>
          <p>
            Today, Minhas Furniture House operates 3 Warehouses in North America and employs approximately 120 employees. The company has ultra modern, state-of-art production facilities at Calgary, Alberta, Canada for manufacturing quality furniture. We are able to deliver quality and value everyday. The Minhas Furniture team includes a full time design group dedicated to creating furniture styles that will complement any decorating style. Our production teams then carefully build each piece, ensuring quality construction and workmanship
          </p>
        </div>
      </div>
    </div>
    <div class="center-wrapper facilities">
      <div class="facilities__section">
        <img src="@/assets/icons/return.svg" />
        <h4>14 Days Return</h4>
        <p>We are able to ship our items to almost anywhere in the world</p>
      </div>
      <div class="facilities__section">
        <img src="@/assets/icons/shield.svg" />
        <h4>Security Payment</h4>
        <p>Usually fall within the 14-day return policy</p>
      </div>
      <div class="facilities__section">
        <img src="@/assets/icons/customer-service.svg" />
        <h4>24/7 Support</h4>
        <p>Securely process all of our card transactions</p>
      </div>
    </div>
    <div class="center-wrapper">
      <div class="photo-text grey">
        <div class="photo-text__content">
          <img src="@/assets/icons/dining.svg" class="photo-text__small-img" />
          <h3>Quality at every step</h3>
          <p>
            We specialize in Recliner Sofas, Leather Sofas, Fabric Sofas, Chairs, Bedroom Suites and Dinning Sets. Innovation has been a great passion and priority for Minhas Furniture House. Beginning with an initial focus in 1997, Minhas Furniture House has come a long way to establish a team of professional and highly talented workers committed to pursue the constant quest for innovation. Our teams work everyday to ensure our customers are provided with highest quality furniture. We also continually test our systems and processes, allowing us to remain in the forefront of furniture manufacturing technology.Our furniture is then subjected to critical evaluations to monitor product safety, durability, appearance, and overall performance.
          </p>
        </div>
        <div class="photo-text__image">
          <img src="@/assets/images/banner.png" />
        </div>
      </div>
    </div>
    <Reviews class="review-padding center-wrapper"></Reviews>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/molecules/Banner.vue'
import Reviews from '@/components/organisms/Reviews.vue'
import TopSection from '@/components/molecules/TopSection'

export default {
  name: 'Home',
  components: {
    Banner,
    Reviews,
    TopSection
  },
  metaInfo() {
    return {
      title: 'About Us'
    }
  }
}
</script>
<style lang="sass" scoped>
.padded
  padding: 2rem 7rem
  &:first-child
    padding-bottom: 0
  @media( max-width: $md-breakpoint)
    padding: 1rem
.photo-design
  margin-top: 5rem
  h1
    font-weight: 400
    margin-bottom: 2.5rem
  &__desc
    color: $text-grey
    line-height: 1.5
    font-weight: 400
    margin-bottom: 2em
    @media( max-width: $md-breakpoint)
      text-align: justify
.photo-text
  display: flex
  flex: 1
  &__image
    display: flex
    flex: 1
    img
      width: 100%
  &__content
    display: flex
    flex-basis: 50%
    flex-direction: column
    align-items: flex-start
    justify-content: center
    text-align: left
    padding: 4rem
    @media( max-width: $md-breakpoint)
      padding: 1rem
  &__small-img
    width: 5rem
    height: 5rem
  h3
    font-weight: 600
    color: $white
    font-size: 34px
    margin-bottom: 1.5em
    @media( max-width: $md-breakpoint)
      line-height: 40px
  p
    line-height: 1.5
    font-size: 15px
    color: $white
.facilities
  display: flex
  @media( max-width: $md-breakpoint)
    flex-direction: column
  &__section
    position: relative
    padding: 1rem
    margin: 5em 0
    @media( max-width: $md-breakpoint)
      margin: 2em 0
    img
      height: 3rem
      width: 3rem
    h4
      font-weight: 400
      margin: 0
    p
      color: $text-grey
      line-height: 1.4
      padding: 0.5em 3em
    &:not(:last-child)
      &:after
        @media( min-width: $md-breakpoint1)
          border: 0.01px solid #e6e6e6
          height: 5rem
          content: ""
          position: absolute
          top: 4rem
          right: 0px
.orange
  background-color: $orange
  @media( max-width: $md-breakpoint)
    flex-direction: column
.grey
  background-color: $grey-4
  @media( max-width: $md-breakpoint)
    flex-direction: column-reverse
.review-padding
  padding: 3em 0
</style>
